
















































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import {
  CustomTable,
  TablePagination,
  CustomInput,
  SvgIcon,
  AddPayeeModal,
  TransferFundModal,
} from "@components";
import { Favorite } from "@/proto/favorites/all_pb";
import { Bank } from "@/proto/banks/all_pb";

const favoritesModule = namespace("favorites");
const configModule = namespace("config");

interface PayeeInfo {
  accountName: string;
  accountNumber: string;
  bank: string;
  actions: string;
}

interface AccountInfo {
  accountName: string;
  accountNumber: string;
}

@Component({
  components: {
    CustomTable,
    TablePagination,
    CustomInput,
    SvgIcon,
    AddPayeeModal,
    TransferFundModal,
  },
})
export default class Payees extends Vue {
  @favoritesModule.Getter("favoritePayees")
  favoritePayees!: Array<Favorite.AsObject>;
  @configModule.Getter("allBanks") allBanks!: Array<[string, Bank.AsObject]>;

  // Component States
  payeeBankCode = "";
  accountInfo: AccountInfo = {
    accountName: "",
    accountNumber: "",
  };

  get PAYEE_COLUMNS() {
    return [
      {
        field: "accountName",
        label: "Account Name",
        sortable: false,
      },
      {
        field: "accountNumber",
        label: "Account Number",
        sortable: false,
      },
      {
        field: "bank",
        label: "Bank / Institution",
        sortable: false,
      },
      {
        field: "actions",
        label: "Actions",
        sortable: false,
      },
    ];
  }

  get payeeFavorites(): Array<PayeeInfo> {
    return this.favoritePayees.map((fav) => {
      const { accountName, accountNumber }: AccountInfo = JSON.parse(
        fav.refcode
      );

      return {
        accountName,
        accountNumber,
        bank: fav.billingcode,
        actions: "",
      };
    });
  }

  _getBankName(code: string): string {
    if (code === "CLRB") {
      return "Cebuana Bank";
    }

    const name = this.allBanks.find((bank) => bank[0] === code);
    return name ? name[1].name : "N/A";
  }

  onTransfer(payload: Record<any, string>) {
    const payeeInfo: PayeeInfo = {
      accountName: payload.accountName,
      accountNumber: payload.accountNumber,
      bank: payload.bank,
      actions: "",
    };

    this.payeeBankCode = payeeInfo.bank;
    this.accountInfo = {
      accountName: payeeInfo.accountName,
      accountNumber: payeeInfo.accountNumber,
    };
    this.$modal.show("transfer-modal");
  }
}
